import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import { Grid, withStyles, createStyles } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import Header from "../common/Header";
import { grey } from "@material-ui/core/colors";

import RegisterIcon from "../img/icons/register.png";
import RedeemIcon from "../img/icons/redeem.png";
import TransactionIcon from "../img/icons/transaction.png";
import SettingsIcon from "../img/icons/settings.png";

const styles = ({ spacing, palette }) =>
  createStyles({
    gridList: {
      "&:nth-child(2)": {
        padding: 26,
      },
    },
    gridTiles: {
      border: "2px solid #0460A9",
      color: "#0460a9",
      padding: 12,
      height: 150,
      fontSize: 18,
      fontWeight: "bold",
      position: "relative",
    },
    icon: {
      bottom: 0,
      right: 0,
      position: "absolute",
      width: "65%",
      height: "auto",
    },
    listHeader: {
      marginTop: spacing(2),
      color: grey[700],
    },
    welcomeBanner: {
      backgroundColor: "#0460a9",
      color: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      height: 220,
    },

    welcomeIconContainer: {},

    welcomeIcon: {
      fontSize: 36,
      backgroundColor: "#FFF",
      color: "#0460a9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 76,
      height: 76,
      borderRadius: 999,
      margin: "auto",
    },

    welcomeName: {
      fontSize: 24,
      margin: 13,
    },
    welcomeMessage: {
      fontSize: 18,
    },

    linkText: { textDecoration: "none" },
    link: { float: "right" },
    divider: {
      marginTop: spacing(2.5),
      marginBottom: spacing(3),
    },
  });

function MenuButton(props) {
  const [isPressed, setIsPressed] = useState(false);
  const history = useHistory();
  return (
    <div
      className={props.classes.gridTiles}
      onClick={() => {
        setIsPressed(true);
        setTimeout(() => {
          setIsPressed(false);
        }, 50);
        history.push(props.to);
      }}
      style={{ backgroundColor: isPressed ? "#c7e6ff" : "#fff" }}
    >
      {props.text}
      <img className={props.classes.icon} src={props.icon} alt={props.text} />
    </div>
  );
}

class HomePage extends Component {
  state = {};

  async componentDidMount() {
    //await this.props.refreshLocalStorage();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.isConnected !== prevProps.isConnected) {
      //await this.props.refreshLocalStorage();
    }
  }

  getInitials(str) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    var acronym = matches.join(""); // JSON

    return acronym.substring(0, 2);
  }

  render() {
    const { classes } = this.props;
    const { translate } = this.props.programStore;
    const { full_name } = this.props.userStore.userProfile;

    return (
      <IonPage>
        <Header title="" searchButton={false} />
        <IonContent className="content">
          <div className={classes.welcomeBanner}>
            <div className={classes.welcomeIconContainer}>
              <div className={classes.welcomeIcon}>
                {full_name ? this.getInitials(full_name) : ""}
              </div>
            </div>
            <div className={classes.welcomeName}>
              {full_name.length > 50
                ? full_name.substring(0, 50) + "..."
                : full_name}
            </div>
            <div className={classes.welcomeMessage}>
              {translate["WELCOME_TO_STELLAR"]}
            </div>
            <div className={classes.sampleStyle}></div>
          </div>

          <Grid container spacing={3} className={classes.gridList}>
            <Grid item xs={6}>
              <MenuButton
                classes={classes}
                text={translate["REGISTER_PATIENT"]}
                icon={RegisterIcon}
                to={"/register"}
              />
            </Grid>
            <Grid item xs={6}>
              <MenuButton
                classes={classes}
                text={translate["REDEEM_DISCOUNT"]}
                icon={RedeemIcon}
                to={"/patientcode"}
              />
            </Grid>
            <Grid item xs={6}>
              <MenuButton
                classes={classes}
                text={translate["TRANSACTION_HISTORY"]}
                icon={TransactionIcon}
                to={"/transaction-history"}
              />
            </Grid>
            <Grid item xs={6}>
              <MenuButton
                classes={classes}
                text={translate["SETTINGS"]}
                icon={SettingsIcon}
                to={"/settings"}
              />
            </Grid>
          </Grid>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({}) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HomePage));
