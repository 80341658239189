import React, { Component } from "react";
import { Plugins, CameraResultType } from "@capacitor/core";
import { storageService } from "../../services/index.service";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  createStyles,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import { ErrorMessage } from "formik";
import generateErrorMessage from "../../utilities/errorMessage.utility";
import CameraIcon from "../../img/icons/camera.png";
import ImageIcon from "../../img/icons/image.png";
import RemoveIcon from "../../img/icons/remove.png";

const { Camera } = Plugins;

const styles = ({ spacing }) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    button: {
      textTransform: "capitalize",
      width: 120,
      height: 36,
      fontSize: 12,
      padding: 0,
      borderColor: "#000",
    },
    uploadField: {
      display: "flex",
      width: "100%",
      border: "1px solid #000",
      padding: 10,
      alignItems: "center",
      height: 60,
    },
    helperText: {
      fontStyle: "italic",
    },
    icon: {
      marginRight: 5,
    },
    iconRemove: {
      marginRight: 5,
    },
    fieldValue: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "200px",
      display: "inline-block",
      fontSize: 14,
      marginLeft: 4,
    },
    removeIcon: {
      marginLeft: "auto",
    },
  });

class ImageUploadField extends Component {
  state = { isLoading: false };

  toggleIsLoading = () => {
    this.setState({ ...this.state, isLoading: !this.state.isLoading });
  };

  render() {
    const {
      field,
      type,
      placeholder,
      label,
      disabled,
      helperText,
      upperCase,
      required,
      classes,
      setFieldValue,
      translate,
    } = this.props;

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        promptLabelPhoto: translate["CHOOSE_FROM_GALLERY"],
        promptLabelPicture: translate["TAKE_A_PICTURE"],
        promptLabelHeader: translate["TAKE_A_PICTURE"],
        promptLabelCancel: "Membatalkan",
      });
      var file = dataURLtoFile(image.dataUrl, "filename.png");
      this.toggleIsLoading();
      try {
        const imageResult = await storageService.uploadPhoto(file);
        // Can be set to the src of an image now
        if (imageResult.result) {
          setFieldValue(field.name, imageResult.result.url);
        } else {
          setFieldValue(field.name, "");
        }
      } catch (err) {
        console.error(err);
        this.props.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        setFieldValue(field.name, "");
      }
      this.toggleIsLoading();
    };

    const removeImage = async () => {
      setFieldValue(field.name, "");
    };

    return (
      <Grid container spacing={4} key={field.name}>
        <Grid item sm={12} xs={12}>
          <div className={classes.uploadField}>
            {typeof field.value === "undefined" ||
            field.value === null ||
            field.value === "" ? (
              <Button
                component="span"
                variant="outlined"
                className={classes.button}
                id={field.name}
                disableElevation
                onClick={takePicture}
                disabled={this.state.isLoading}
              >
                <img src={CameraIcon} alt="Camera" className={classes.icon} />{" "}
                {translate["TAKE_PHOTO"]}
              </Button>
            ) : (
              <React.Fragment>
                <div>
                  <img
                    src={ImageIcon}
                    alt="imageicon"
                    className={classes.icon}
                  />
                </div>
                <div className={classes.fieldValue}>
                  {field.value && translate["IMAGE_UPLOADED"]}
                </div>
                <div className={classes.removeIcon}>
                  <img
                    src={RemoveIcon}
                    alt="Remove"
                    onClick={() => removeImage()}
                    className={classes.icon}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          {this.state.isLoading && <LinearProgress />}
          <Typography
            variant="caption"
            display="block"
            className={classes.helperText}
            gutterBottom
          >
            {label}
          </Typography>
          <ErrorMessage
            name={field.name}
            render={(message) => {
              return (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="error"
                >
                  {message}
                </Typography>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translate: state.programStore.translate,
});

const mapDispatchToProps = ({ transactionStore, uiStore }) => ({
  ...uiStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ImageUploadField));
