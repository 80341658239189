import { Device } from "@capacitor/core";
import axios from "axios";
import { AuthService } from "../services/AuthService";
import stores from "../models/index.model";
import jwtDecode from "jwt-decode";

// TODO: @JUSTIN IMPROVE THIS FILE
class ApiClient {
  constructor() {
    axios.defaults.headers.common["Accept-Language"] =
      window.config.REQUEST_HEADER_ACCEPTED_LANGUAGE;
  }

  async get(path, reqQuery) {
    try {
      await this.inspectAndRenewTokens();
      const queryString = reqQuery
        ? `?${Object.keys(reqQuery)
            .map((key) => key + "=" + reqQuery[key])
            .join("&")}`
        : "";

      const { operatingSystem, platform } = await Device.getInfo();
      const config = {
        headers:{
          'X_DEVICE_TYPE': ["android", "ios"].includes(operatingSystem) ?  operatingSystem : platform,
        }
      };

      const response = await axios.get(`${path}${queryString}`,config);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async post(path, reqBody) {
    try {
      await this.inspectAndRenewTokens();
      const response = await axios.post(path, reqBody);

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async upload(path, reqBody) {
    try {
      await this.inspectAndRenewTokens();
      var formData = new FormData();
      formData.append("file", reqBody);
      const response = await axios.post(path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async put(path, reqBody) {
    try {
      await this.inspectAndRenewTokens();
      const response = await axios.put(path, reqBody);

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async del(path, reqBody) {
    try {
      await this.inspectAndRenewTokens();
      const response = await axios.delete(path, { data: reqBody });

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  setAuthenticationHeaders(token) {
    this.accessToken = token;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  unsetAuthenticationHeaders() {
    delete axios.defaults.headers.common.Authorization;
  }

  async setLoggedInUser(tokens, fromRegister) {
    try {
      const accessToken = tokens.accessToken ?? ''
      const refreshToken = tokens.refreshToken ?? ''
      const user = jwtDecode(accessToken);

      this.loggedInUser = user;
      this.accessToken = accessToken;
      await AuthService.storeAccessToken(accessToken);
      await AuthService.storeRefreshToken(refreshToken);
      if (accessToken) {
        this.setAuthenticationHeaders(accessToken);
      }

      // IF FROM REGISTRATION, USE TOKENS FROM RESPONSE
      await AuthService.setFromRegister(fromRegister);

      return user;
    } catch (e) {
      console.error(e);
    }
  }

  async setLoggedInUserFromRegister() {
    try {
      await AuthService.getAccessToken();
      await AuthService.getRefreshToken();

      if (AuthService.accessToken) {
        this.setAuthenticationHeaders(AuthService.accessToken);
      }

      return jwtDecode(AuthService.accessToken);;
    } catch (e) {
      console.error(e);
    }
  }

  refreshUserToken() {
    console.log("Refreshing registration token...");
    const { KEYCLOAK_CONFIG } = window.config;

    const refreshTokenEndpoint = `${KEYCLOAK_CONFIG.auth_server_url}/realms/${KEYCLOAK_CONFIG.realm}/protocol/openid-connect/token`;

    return axios({
      method: "post",
      url: refreshTokenEndpoint,
      data: {
        grant_type: "refresh_token",
        client_id: KEYCLOAK_CONFIG.client_id,
        refresh_token: this.refreshToken,
      },
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  async inspectAndRenewTokens() {
    try {
      if (!this.loggedInUser) {
        return console.log("Proceeding as anonymous user...");
      }

      // IF NOT FROM REGISTRATION, USE AUTHSERVICE
      if (!AuthService.accessToken) {
        console.log("Not from register. using auth service...");
        const token = await AuthService.Instance.getValidToken();
        this.setAuthenticationHeaders(token.accessToken);
      } else {
        console.log("from register. using standard token...");
        const today = Math.floor(Date.now() / 1000); // limit to 10 digits

        const accessToken = jwtDecode(this.accessToken);

        // const refreshToken = jwtDecode(this.refreshToken);

        // checks if access token is expired.
        if (accessToken.exp <= today) {
          console.error(`Access token is expired. Checking Refresh Token...`);

          // checks if refresh token is expired.
          // if (refreshToken.exp <= today) {
          //   console.error(`Refresh token is expired.`);
          // }

          // renew access token.
          const renewedUser = await this.refreshUserToken();
          return this.setLoggedInUser(renewedUser, true);
        }

        console.log("Access token still valid");
      }
    } catch (e) {
      console.error(e);

      try {
        await AuthService.clearStorage();
        await AuthService.Instance.storage.clear();
      } catch (e) {
        console.log(e);
      }

      window.location.reload();
    }

    return this.loggedInUser;
  }
}

export default new ApiClient();
